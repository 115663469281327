import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Text,
  Input,
  Icon,
  useToast,
  Link,
} from "@chakra-ui/react";
import api from "../../services/api";
import { ProlabContext } from "../../contexts/Prolab";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";
import { LuDownloadCloud } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import { environment } from "../../environment";

export default function ManualProvatis() {
  const { listDocumentsApi, deleteDocumentApi } = useContext(ProlabContext);
  const toast = useToast();
  const [description, setDescription] = useState("");
  const [savedInfos, setSavedInfos] = useState([]);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("category_id", "1");
    formData.append("title", title);
    formData.append("file", file);

    if (title === "") {
      toast({
        title: "Preencha o campo Título",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (file === null) {
      toast({
        title: "Selecione um arquivo para enviar",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      try {
        const response = await api.post("documents", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        toast({
          title: "Upload feito com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTitle("");
        setFile("");
        setFile([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        getDocumentsManual();
      } catch (error) {
        console.error(error);
        toast({
          title: "Não foi possível enviar o documento. Tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  async function getDocumentsManual() {
    try {
      const response = await listDocumentsApi(1);
      setSavedInfos(response.data?.[1]);
      console.log(response.data?.[1]);
    } catch (error) {
      console.log(error);
      setSavedInfos();
    }
  }

  async function deleteDocument(id) {
    const mensagem = "Tem certeza que deseja excluir o arquivo?";
    const confirmacao = window.confirm(mensagem);
    if (confirmacao){
      try {
        const response = await deleteDocumentApi(id);
        getDocumentsManual();
        toast({
          title: "Arquivo deletado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getDocumentsManual();
  }, []);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px"}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        w={"57vw"}
        p={5}
        position={"absolute"}
        ml={"250px"}
        mt={"100px"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <h1 fontSize="md">Atualizar manual</h1>
          <Button onClick={handleUpload}>Salvar</Button>
        </Box>

        <Box>
          <Text>Título</Text>
          <Input
            placeholder={"Digite Aqui"}
            value={title}
            onChange={handleTitleChange}
          />
        </Box>

        <Box mr="20px" mt={"20px"}>
          <Text fontSize={12}>
            Envie arquivos nas extensões de docx. doc. e PDF.
          </Text>
          <Input type="file" ref={fileInputRef} onChange={handleFileChange} />
        </Box>
      </Box>

      <Box mt={"350px"}></Box>
      {savedInfos?.map((info, index) => (
        <Box
          key={index}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          w={"57vw"}
          ml={"250px"}
          mt={"20px"}
        >
          <Box
            mt={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"justify-content-end"}
          ></Box>
          <Box mt={"20px"} mb={"15px"} display={"flex"} alignItems={"end"}>
            <Text fontSize={"24px"}>{info.title}</Text>
            <Box>
              {" "}
              <Box
                background={"black"}
                borderRadius={"200px"}
                padding={1}
                marginLeft={"80px"}
              >
                <Link
                  href={
                    environment.baseURL + "/storage/uploads/documents/" +
                    info.document_path
                  }
                >
                  <LuDownloadCloud
                    color="white"
                    fontSize={"24px"}
                    cursor={"pointer"}
                  />
                </Link>
              </Box>
            </Box>
            <Box marginLeft={"30px"}>
              {" "}
              <AiOutlineDelete
                cursor={"pointer"}
                fontSize={"25px"}
                color="red"
                onClick={() => deleteDocument(info.id)}
              ></AiOutlineDelete>
            </Box>
          </Box>

          {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
        </Box>
      ))}
    </>
  );
}
