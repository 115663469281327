import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Container,
  Text,
  Input,
  NumberInputField,
  NumberInput,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { SearchIcon } from "@chakra-ui/icons";

export default function Users() {
  const { listExpertsApi, listExpertsAllApi } = useContext(UserContext);
  const [experts, setExperts] = useState();
  const [allExperts, setAllExperts] = useState();
  const navigate = useNavigate();
  const perfil = 'expert';
  const [searchExpert, setSearchExpert] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchID, setSearchID] = useState("");
  function goToScore(value) {
    localStorage.setItem("expertID", value.id);
    navigate(`/profile/expert/${value.user.name}`);
  }
  function goToProfile(value) {
    navigate(`/configuracoes/${perfil}/${value.id}`);
  }

  async function loadExperts() {
    let filteredExperts = allExperts;
    if (!searchExpert && !searchEmail && !searchID) {
      setExperts(allExperts);
    } else {
      if (searchID) {
        filteredExperts = filteredExperts.filter(
          (expert) => expert.user.id == searchID
        );
        setExperts(filteredExperts);
      } else {
        if (searchExpert) {
          filteredExperts = filteredExperts.filter((expert) =>
            expert.user.name.toLowerCase().includes(searchExpert.toLowerCase())
          );
        }
        if (searchEmail) {
          filteredExperts = filteredExperts.filter((expert) =>
            expert.user.email.toLowerCase().includes(searchEmail.toLowerCase())
          );
        }
        setExperts(filteredExperts);
      }
    }
  }
  function cleanFilters() {
    setSearchExpert("");
    setSearchEmail("");
    setSearchID("");
    setExperts(allExperts);
  }
  async function getExperts() {
    try {
      const experts = await listExpertsAllApi();
      setExperts(experts.data?.[1]);
      setAllExperts(experts.data?.[1]);
      console.log(experts);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getExperts();
  }, []);

  return (
    <>
      <Navbar />
      <Container
        position={"absolute"}
        top={120}
        ml={{ base: 0, md: 300 }}
        minWidth={{ base: 0, md: "75vw" }}
      >
        <Flex display={"inline-block"} marginBottom={"15px"}>
          <Button colorScheme="green">
            <Link to="/adicionar/usuario"> Adicionar novo experts </Link>
          </Button>
        </Flex>
        <Box
          bg={"#ffff"}
          borderRadius={"15px 15px 0 0"}
          boxShadow={"0px 7px 16px 0px #EAEAEA"}
        >
          <Box
            bg={"#ffff"}
            borderRadius={"15px 15px 15px 15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            padding={"2rem"}
          >
            <Box
              //marginTop={"1rem"}
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Box maxWidth={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Nome:
                </Text>
                <Input
                  variant="outline"
                  value={searchExpert}
                  onChange={(e) => setSearchExpert(e.target.value)}
                />
              </Box>
              <Box maxWidth={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Email:
                </Text>
                <Input
                  variant="outline"
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                />
              </Box>
              <Box width={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  ID do Expert:
                </Text>
                <NumberInput
                  value={searchID}
                  onChange={(newID) => setSearchID(newID)}
                  variant="outline"
                  placeholder="Digite um ID do expert"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
              <Box>
                <Button
                  marginTop={"1rem"}
                  colorScheme="green"
                  fontSize={"14px"}
                  leftIcon={<SearchIcon />}
                  onClick={() => loadExperts()}
                >
                  Buscar
                </Button>
              </Box>
              <Box marginLeft={"auto"}>
                <Button
                  marginTop={"1rem"}
                  fontSize={"14px"}
                  onClick={cleanFilters}
                  bg="white"
                  border="1px solid black"
                >
                  <Text color={"black.100"}>Limpar Filtros</Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Flex display={"flex"} justifyContent={"center"}>
          <Box mt={"10px"} width={"100%"}>
            <Box>
              <TableContainer>
                <Table variant="striped" colorScheme="green">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Nome</Th>
                      <Th>Email</Th>
                      <Th>Ação</Th>
                    </Tr>
                  </Thead>
                  {experts && (
                    <Tbody>
                      {experts.map((index) => (
                        <Tr key={index.user_id}>
                          <Td>{index.user.id}</Td>
                          <Td>{index.user.name}</Td>
                          <Td>{index.user.email}</Td>
                          <Td>
                            <Button onClick={() => goToScore(index)}>
                              Acessar desempenho
                            </Button>
                          </Td>
                          <Td>
                            <Button onClick={() => goToProfile(index)}>
                              Acessar perfil
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
