import React, { useState, useContext, useEffect } from "react";
import {
  useToast,
  Box,
  Text,
  Tabs,
  Tab,
  TabList,
  AspectRatio,
  Badge,
  Tooltip
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { ProlabContext } from "../../contexts/Prolab";

export default function VideoProvatis() {
  const toast = useToast();
  const {
    listVideosProlabApi,
  } = useContext(ProlabContext);
  const [categoryName, setCategoryName] = useState("");
  const [categories, setCategories] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [categoryId, setCategoryId] = useState(4);
  const { learningCategoriesApi } = useContext(OrderContext);
  const [videos, setVideos] = useState("");;

  function limitToTwoWords(text) {
    if (text.length > 16) {
      const islimitWords = true;
      return {text: text.slice(0, 16) + " ...", islimitWords}
    }
    return text;
  }

  async function listVideo() {
    try {
      const response = await listVideosProlabApi(categoryId);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCategoriesApi() {
    try {
      const response = await learningCategoriesApi();
      const categoriesOrdered = [...response.data?.[0]]?.sort((a, b) => {
        const nameA = a.category?.toLowerCase() || ''; 
        const nameB = b.category?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      console.log(response.data?.[0]);
      setCategories(categoriesOrdered);
    } catch (error) {
      console.log(error);
    }
  }

  async function listVideoCategory(id) {
    try {
      const response = await listVideosProlabApi(id);
      console.log(response);
      setVideos(response.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    setCategoryId(category);
    setCategoryName(category.category);

    listVideoCategory(category.id);

    try {
      const response = await listVideosProlabApi(category.id);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getCategoriesApi();
    }, 1000); // Ag

    listVideo();
  }, []);
  return (
    <>
      {categories && (
        <>
          <Tabs
            variant="soft-rounded"
            colorScheme="blackAlpha"
            backgroundColor="white"
          >
            <Box
              justifyContent={"center"}
              mt={"370px"}
              w={"250px"}
              display={"block"}
              position={"absolute"}
              left={"-15px"}
              backgroundColor={"#ffff"}
              p={8}
              borderRadius={"15px "}
              boxShadow={"0px 7px 16px 4px #EAEAEA"}
            >
              <Text>Categorias</Text>

              <TabList display={"block"} borderBottom={"0"}>
                {categories.map((index) => {
                  const { text, islimitWords } = limitToTwoWords(
                    index.category
                  );
                  return islimitWords ? (
                    <Tooltip
                      label={index.category}
                      fontSize="sm"
                      hasArrow
                      bg="white"
                      color="black"
                      key={index.category}
                    >
                      <Tab
                        mt={"1.5px"}
                        fontSize={"14px"}
                        _hover={{ backgroundColor: "black", color: "white" }}
                        _selected={{ backgroundColor: "black", color: "white" }}
                        onClick={() => handleCategoryClick(index)}
                      >
                        {text}
                      </Tab>
                    </Tooltip>
                  ) : (
                    <Tab
                      mt={"1.5px"}
                      fontSize={"14px"}
                      _hover={{ backgroundColor: "black", color: "white" }}
                      _selected={{ backgroundColor: "black", color: "white" }}
                      onClick={() => handleCategoryClick(index)}
                      key={index.category}
                    >
                      {index.category}
                    </Tab>
                  );
                })}
              </TabList>
            </Box>
          </Tabs>

          <Box marginTop={"110px"}>
            {videos &&
              videos.map((index) => (
                <Box
                  key={index.id}
                  bg={"#ffff"}
                  borderRadius={"15px "}
                  boxShadow={"0px 7px 16px 4px #EAEAEA"}
                  p={5}
                  w={"75%"}
                  ml={"250px"}
                  mt={"20px"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    borderBottom={"1px"}
                    borderColor={"#D2D1D166"}
                  >
                    <Text fontSize={"20px"}>Vídeo Provatis</Text>
                    <Box
                      m={5}
                      gap={3}
                      display={"flex"}
                      justifyContent={"end"}
                      alignItems={"center"}
                    ></Box>
                  </Box>

                  <Box
                    mt={"20px"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      p={2}
                      borderRadius={"20px"}
                      background={"black"}
                      color={"white"}
                      fontSize={"14px"}
                    >
                      {index.category_name}
                    </Text>
                  </Box>
                  <Box>
                    {index?.tags?.map((tag, tagIndex) => (
                      <Badge
                        key={tagIndex}
                        colorScheme="green"
                        borderRadius="full"
                        mt={2}
                        mr={2}
                      >
                        {tag.category_name}
                      </Badge>
                    ))}
                  </Box>
                  <Box mt={"20px"} mb={"15px"}>
                    <Text fontSize={"24px"}>{index.title}</Text>
                  </Box>
                  <Box>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: index.description,
                      }}
                    />
                  </Box>
                  <AspectRatio ratio={16 / 9}>
                    <iframe src={index.video} allowFullScreen />
                  </AspectRatio>
                  {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
                </Box>
              ))}
          </Box>
        </>
      )}
    </>
  );
}
