import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  Input,
  NumberInputField,
  NumberInput,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Icon,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import {
  MdEditSquare,
  MdRemoveRedEye,
  MdOutlineTextSnippet,
} from "react-icons/md";
import { LuEye } from "react-icons/lu";
import { SearchIcon } from "@chakra-ui/icons";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import { LuClock4 } from "react-icons/lu";
import { LuFlaskConical } from "react-icons/lu";
import { FiZap } from "react-icons/fi";

export default function Orders() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listOrdersApi, listOrdersExpertApi, getOrderStatus } =
    useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [typeOrder, setTypeOrder] = useState("general");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [deadline, setDeadline] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [client, setClient] = useState("");
  const currentYear = new Date().getFullYear();
  function limitToTwoWords(text) {
    if (typeof text !== 'string') {
      return ''; // Return an empty string or handle the case as needed
  }
    const words = text.split(" ");
    
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  function handleEditService(index) {
    localStorage.setItem("orderID", index.orderID);
    const type = typeOrder === "general" ? "geral" : "edicao-normas";
    if (user.role_id === 1) {
      navigate(`/servico/${index.id}/${type}`);
    } else {
      navigate(`/expert/servico/${index.id}/${type}`);
    }
  }
  const selectDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  // Função para carregar pedidos com base na página fornecida
  async function loadOrders(page) {
    try {
      let params = {
        page,
      };

      if (typeOrder) {
        params.type_service = typeOrder;
      }

      if (title) {
        params.title = title;
      }

      if (id) {
        params.id = id;
      }

      if (startDate) {
        params.start_date = startDate;
      }

      if (endDate) {
        params.end_date = endDate;
      }

      if (status && status.value !== '') {
        params.status = status;
      }

      if (client) {
        params.client = client;
      }

      const ordersApi =
        user.role_id === 1
          ? await listOrdersApi(params)
          : await listOrdersExpertApi(params);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);
    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }

  function handleChangeTab(index) {
    setCurrentPage(1);
    if (index === 0) {
      setTypeOrder("general");

      return;
    }

    setTypeOrder("edit-norms");
  }

  async function getStatus() {
    try {
      const statusList = await getOrderStatus();
      const statusListsOrdered = [...statusList?.data?.[0]]?.sort((a, b) => {
        const nameA = a.status?.toLowerCase() || ''; 
        const nameB = b.status?.toLowerCase() || '';
        return nameA.localeCompare(nameB); 
      });
      setStatusList(statusListsOrdered);
    } catch (error) {
      console.log(error);
    }
  }
  function cleanFilters() {
    setStartDate("");
    setEndDate("");
    setClient("");
    setDeadline("");
    setStatus("");
    setId("");
    setTitle("");
  }
  useEffect(() => {
    if (!startDate && !endDate && !client && !deadline && !status && !id && !title) {
    loadOrders(currentPage);
    }
  }, [startDate, endDate, client, deadline, status, id, title]);
  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    loadOrders(currentPage);
  }, [currentPage, typeOrder]);

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: 270, // Define a margem em telas maiores (md)
        }}
        minWidth={"78vw"}
      >
        <Box
          bg={"#ffff"}
          borderRadius={"15px 15px 0 0"}
          boxShadow={"0px 7px 16px 0px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={"flex"} alignItems={"center"}>
              <MdOutlineTextSnippet color="#04D194" size={"24px"} />
              <Text
                marginInline={"0.5rem"}
                fontSize={"24px"}
                fontWeight={"400"}
              >
                Serviços
              </Text>
            </Box>

            <Spacer />

            {/* {user.role_id === 1 ? (
              <>
                <Box mt={2}>
                  <Button colorScheme="green" fontSize={"14px"}>
                    <Link to="/editions/servicos">
                      {" "}
                      Ver Edições das normas da revista{" "}
                    </Link>
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box mt={2}>
                  <Button fontSize={"14px"} colorScheme="green">
                    <Link to="/editions/servicos/expert">
                      {" "}
                      Ver Edições das normas da revista{" "}
                    </Link>
                  </Button>
                </Box>
              </>
            )} */}

            <Spacer />

            {user.role_id === 1 ? (
              <>
                <Box>
                  <Button
                    colorScheme="green"
                    fontSize={"14px"}
                    _hover={{
                      backgroundColor: "#33BA9D",
                    }}
                  >
                    <Link
                      to={`/adicionar/servico/${
                        typeOrder === "general" ? "geral" : "edicao-normas"
                      }`}
                    >
                      {`Adicionar novo serviço ${
                        typeOrder === "general"
                          ? "geral"
                          : "de edição de normas"
                      }`}
                    </Link>
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
          </Flex>
        </Box>

        <Tabs onChange={(index) => handleChangeTab(index)}>
          <Box bg={"#ffff"} boxShadow={"0px 7px 16px 4px #EAEAEA"}>
            <TabList>
              <Tab _selected={{ color: "#04D194" }}>Serviços gerais</Tab>
              <Tab _selected={{ color: "#04D194" }}>Edição de normas</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="#04D194"
              borderRadius="2px"
            />
          </Box>

          <Box
            bg={"#ffff"}
            borderRadius={"0 0 15px 15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            padding={"2rem"}
          >
            <Text fontSize={"20px"} fontWeight={"400"}>
              Busca e Filtros
            </Text>

            <Box
              marginTop={"1rem"}
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
            >
              <Box maxWidth={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Título do artigo
                </Text>
                <Input
                  variant="outline"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Box>

              <Box width={"8rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  ID do serviço
                </Text>
                <NumberInput
                  value={id}
                  onChange={(newId) => setId(newId)}
                  variant="outline"
                  placeholder="Digite um ID do serviço"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>

              <Box width={"14rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Prazo final
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={selectDates}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  locale={ptBR}
                  placeholderText="Selecione o período"
                  dateFormat="yyyy/MM/dd"
                  className="custom-date-input"
                  showYearDropdown
                  monthsShown={2}
                  minDate={new Date(2023, 0, 1)}
                  maxDate={new Date(currentYear + 1, 11, 31)}
                  dropdownMode="select"
                />
              </Box>
              {user.role_id === 1 && (
                <Box width={"12rem"} marginRight={"2rem"}>
                  <Text fontSize={"14px"} fontWeight={"400"}>
                    Status
                  </Text>
                  <Select
                    options={[
                      { value: "", label: "Todos" },
                      ...statusList?.map((index) => ({
                        value: index.id,
                        label: index.status,
                      })),
                    ]}
                    value={
                      status === ""
                        ? { value: "", label: "Todos" }
                        : status
                        ? {
                            value: status,
                            label: statusList.find((option) => option.id === status)?.status,
                          }
                        : null
                    }
                    variant="outline"
                    placeholder=" "
                    onChange={(selectedOption) => {
                      setStatus(selectedOption?.value || "");
                    }}
                  ></Select>
                </Box>
              )}
              {user.role_id === 1 && (
                <Box maxWidth={"12rem"} marginRight={"2rem"}>
                  <Text fontSize={"14px"} fontWeight={"400"}>
                    Cliente
                  </Text>
                  <Input
                    variant="outline"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                  />
                </Box>
              )}
              <Box display={"flex"} marginRight={"2rem"}>
                <Button
                  marginTop={"1rem"}
                  colorScheme="green"
                  fontSize={"14px"}
                  leftIcon={<SearchIcon />}
                  onClick={() => loadOrders(1)}
                >
                  Buscar
                </Button>
              </Box>
              <Button
                marginLeft={"10px"}
                marginTop={"1rem"}
                fontSize={"14px"}
                onClick={cleanFilters}
                bg="white"
                border="1px solid black"
              >
                <Text color={"black.100"}>Limpar Filtros</Text>
              </Button>
            </Box>
          </Box>

          <TabPanels>
            <TabPanel>
              <Box>
                <Box
                  backgroundColor={"white"}
                  mt={5}
                  boxShadow={"0px 8px 16px -10px"}
                  borderRadius={"8px"}
                  marginBottom={5}
                >
                  <TableContainer>
                    <Table fontSize={"12px"}>
                      <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                        <Tr>
                          <Th>ID</Th>
                          {user.role_id === 1 ? <Th>Cliente</Th> : ""}

                          <Th>Titulo</Th>
                          {user.role_id === 1 ? <Th>Expert</Th> : ""}

                          <Th>Status</Th>
                          <Th>Etapa</Th>
                          <Th>Plano</Th>
                          <Th>Tipo de Documento</Th>
                          <Th>Prazo Final</Th>
                        </Tr>
                      </Thead>
                      <Tbody color={"#888686"} fontWeight={"300"}>
                        {orders.length > 0 &&
                          orders.map((index) => (
                            <Tr key={index.id}>
                              <Td>{index.id}</Td>
                              {user.role_id === 1 ? (
                                <Td>{limitToTwoWords(index.user.name)}</Td>
                              ) : (
                                ""
                              )}

                              <Td noOfWords={5}>
                                {limitToTwoWords(index.title)}
                              </Td>
                              {user.role_id === 1 ? (
                                <Td>{index.order_expert_historics[0]?.user?.name}</Td>
                              ) : (
                                ""
                              )}
                              <Td>{index.status.status}</Td>
                              <Td>{index.step.step}</Td>
                              <Td>{index.plan.name}</Td>
                              <Td>{index.type_document}</Td>
                              <Td>
                                {format(parseISO(index.deadline), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                <Box display={"inline-flex"} fontSize={"16px"}>
                                  <Box
                                    cursor={"pointer"}
                                    color={"#115EFA"}
                                    onClick={() => handleEditService(index)}
                                  >
                                    <MdEditSquare />
                                  </Box>
                                  <Box ml={2} color={"#00FF94"}>
                                    <MdRemoveRedEye
                                      onClick={() => previewOrder(index)}
                                      cursor={"pointer"}
                                    />
                                  </Box>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box>
                  <Button
                    colorScheme="green"
                    onClick={() => goToPreviousPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    fontSize={"12px"}
                  >
                    &laquo; Anterior
                  </Button>
                  <Button
                    colorScheme="green"
                    ml={2}
                    onClick={() => goToNextPage(currentPage + 1)}
                    disabled={currentPage === lastPage}
                    fontSize={"12px"}
                  >
                    Próxima &raquo;
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <Box
                  backgroundColor={"white"}
                  mt={5}
                  boxShadow={"0px 8px 16px -10px"}
                  borderRadius={"8px"}
                  marginBottom={5}
                >
                  <TableContainer>
                    <Table fontSize={"12px"}>
                      <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                        <Tr>
                          <Th>ID</Th>
                          {user.role_id === 1 ? <Th>Cliente</Th> : ""}

                          <Th>Titulo</Th>
                          {user.role_id === 1 ? <Th>Expert</Th> : ""}

                          <Th>Status</Th>
                          <Th>Etapa</Th>
                          <Th>Plano</Th>
                          <Th>Tipo de Documento</Th>
                          <Th>Prazo Final</Th>
                        </Tr>
                      </Thead>
                      <Tbody color={"#888686"} fontWeight={"300"}>
                        {orders.length > 0 &&
                          orders.map((index) => (
                            <Tr key={index.orderID}>
                              <Td>{index.orderID}</Td>
                              {user.role_id === 1 ? (
                                <Td>{limitToTwoWords(index.client)}</Td>
                              ) : (
                                ""
                              )}

                              <Td noOfWords={5}>
                                {limitToTwoWords(index.title)}
                              </Td>
                              {user.role_id === 1 ? (
                                <Td>{index.expert}</Td>
                              ) : (
                                ""
                              )}
                              <Td>{index.status.status}</Td>
                              <Td>{index.step.step}</Td>
                              <Td>{index.plan.name}</Td>
                              <Td>{index.type_document}</Td>
                              <Td>
                                {format(new Date(index.deadline), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                <Box display={"inline-flex"} fontSize={"16px"}>
                                  <Box
                                    cursor={"pointer"}
                                    color={"#115EFA"}
                                    onClick={() => handleEditService(index)}
                                  >
                                    <MdEditSquare />
                                  </Box>
                                  <Box ml={2} color={"#00FF94"}>
                                    <MdRemoveRedEye
                                      onClick={() => previewOrder(index)}
                                      cursor={"pointer"}
                                    />
                                  </Box>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box>
                  <Button
                    colorScheme="green"
                    onClick={() => goToPreviousPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    fontSize={"12px"}
                  >
                    &laquo; Anterior
                  </Button>
                  <Button
                    colorScheme="green"
                    ml={2}
                    onClick={() => goToNextPage(currentPage + 1)}
                    disabled={currentPage === lastPage}
                    fontSize={"12px"}
                  >
                    Próxima &raquo;
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent
          sx={{
            maxW: "653px",
            maxH: "380px",
            padding: "2px 0px 0px 0px",
            gap: "8px",
            borderRadius: "8px 8px 8px 8px",
            opacity: 0,
          }}
        >
          <ModalHeader>
            <Box
              display="flex"
              alignItems="center"
              fontSize="16px"
              marginBottom={"2px"}
            >
              <Box color={"#04D194"}>
                <LuEye size={16} />
              </Box>
              <Box
                ml={2}
                fontSize="16px"
                fontFamily="Poppins"
                fontWeight="400"
                lineHeight="32px"
                textAlign="left"
                textUnderlinePosition="from-font"
                textDecorationSkipInk="none"
              >
                Preview do Serviço
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          {orderPreview && (
            <ModalBody>
              <Box marginTop={"-16px"} marginBottom={"16px"} gap={"8px"}>
                <Text
                  fontSize={"12px"}
                  fontFamily="Poppins"
                  fontWeight="300"
                  lineHeight="18px"
                  color={"#05070B99"}
                >
                  Título
                </Text>
                <Text>{orderPreview.title}</Text>

                <Box display={"inline-flex"}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"100px"}
                    padding="4px 12px"
                    backgroundColor={"#3A7DFF14"}
                  >
                    <Text
                      fontSize={"12px"}
                      fontFamily="Poppins"
                      lineHeight="18px"
                      color={"#3A7DFF"}
                    >
                      {orderPreview.status.status}
                    </Text>
                  </Box>
                  <Box
                    ml={5}
                    display="inline-flex"
                    alignItems="center"
                    fontFamily="Poppins"
                  >
                    <Icon color={"#04D194"} fontSize={18}>
                      <LuClock4 />
                    </Icon>
                    <Text
                      fontWeight="600"
                      color={"#565656"}
                      fontSize={"14px"}
                      ml={1}
                      weight={"600px"}
                    >
                      Prazo Final:{" "}
                    </Text>
                    <Text
                      color={"#565656"}
                      fontSize={"14px"}
                      ml={2}
                      lineHeight="24px"
                      fontWeight="400"
                    >
                      {format(parseISO(orderPreview.deadline), "dd/MM/yyyy")}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box
                fontSize={"14px"}
                display={"inline-flex"}
                gap={"24px"}
                paddingY="16px"
                paddingX="2px"
                borderBottom={"1px"}
                borderTop={"1px"}
                borderColor={"#DBDBDB"}
                width="100%"
                maxWidth="605px"
              >
                <Box >
                  <Text
                    fontFamily="Poppins"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="18px"
                    textAlign="left"
                    color={"#05070B99;"}
                  >
                    ID
                  </Text>
                  <Text
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="21px"
                    textAlign="left"
                    color={"#888686"}
                  >
                    {orderPreview.id}
                  </Text>
                </Box>
                <Box ml={2}>
                  <Text
                    fontFamily="Poppins"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="18px"
                    textAlign="left"
                    color={"#05070B99;"}
                  >
                    Número de palavaras
                  </Text>
                  <Text
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="21px"
                    textAlign="left"
                    color={"#888686"}
                  >
                    {" "}
                    {orderPreview.words_number_actual}
                  </Text>
                </Box>
                <Box ml={2}>
                  <Text
                    fontFamily="Poppins"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="18px"
                    textAlign="left"
                    color={"#05070B99;"}
                  >
                    Plano
                  </Text>
                  <Box display="flex" alignItems="center" gap={"8px"}>
                    <FiZap color={"#04D194"}/>
                    <Text
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="21px"
                      textAlign="left"
                      color={"#888686"}
                    >
                      {" "}
                      {orderPreview.plan.name}
                    </Text>
                  </Box>
                </Box>
                <Box ml={2}>
                  <Text
                    fontFamily="Poppins"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="18px"
                    textAlign="left"
                    color={"#05070B99"}
                  >
                    Tipo de documento
                  </Text>
                  <Box display="flex" alignItems="center" gap={"8px"}>
                    <Icon color={"#04D194"} fontSize={14} as={LuFlaskConical} />
                    <Text
                      mt={1}
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="21px"
                      textAlign="left"
                      color={"#888686"}
                    >
                      {orderPreview.type_document}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </ModalBody>
          )}

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
