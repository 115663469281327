import React, { useState, useContext, useEffect } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { LuDownloadCloud } from "react-icons/lu";
import { ProlabContext } from "../../contexts/Prolab";
import { environment } from "../../environment";

export default function ArticleExpert() {
  const [savedInfos, setSavedInfos] = useState([]);
  const { listDocumentsApi } = useContext(ProlabContext);

  async function getDocumentsArticle() {
    try {
      const response = await listDocumentsApi(4);
      setSavedInfos(response.data?.[1]);
      console.log(response.data?.[1]);
    } catch (error) {
      console.log(error);
      setSavedInfos();
    }
  }

  useEffect(() => {
    getDocumentsArticle();
  }, []);


  return (
    <>
      {savedInfos.map((info, index) => (
        <Box
          key={index}
          bg={"#ffff"}
          borderRadius={"15px"}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          w={"57vw"}
          p={5}
          ml={"250px"}
          mt={"100px"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px"}
            borderColor={"#D2D1D166"}
          >
            <Text fontSize={"20px"}>Artigos Provatis </Text>
          </Box>
          <Box
            mt={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          ></Box>
          <Box
            mt={"20px"}
            mb={"15px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"24px"}>{info.title}</Text>
            <Box background={"black"} borderRadius={"200px"} padding={1}>
              <Link
                href={
                  environment.baseURL +
                  "/storage/uploads/documents/" +
                  info.document_path
                }
              >
                <LuDownloadCloud
                  color="white"
                  fontSize={"24px"}
                  cursor={"pointer"}
                />
              </Link>
            </Box>
          </Box>

          {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
        </Box>
      ))}
    </>
  );
}
