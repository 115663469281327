import React, { useState, useContext, useEffect, useRef } from "react";
import { Box, Button, Text, Input, useToast, Link } from "@chakra-ui/react";
import api from "../../services/api";
import { AiOutlineDelete } from "react-icons/ai";
import { LuDownloadCloud } from "react-icons/lu";
import { ProlabContext } from "../../contexts/Prolab";
import { format, parseISO } from "date-fns";
import { environment } from "../../environment";

export default function ArticleProvatis() {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState("");
  const [savedInfos, setSavedInfos] = useState([]);
  const { listDocumentsApi, deleteDocumentApi } = useContext(ProlabContext);
  const toast = useToast();
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  async function getDocumentsArticle() {
    try {
      const response = await listDocumentsApi(4);
      setSavedInfos(response.data?.[1]);
      console.log(response.data?.[1]);
    } catch (error) {
      console.log(error);
      setSavedInfos();
    }
  }

  async function deleteDocument(id) {
    const mensagem = "Tem certeza que deseja excluir o arquivo?";
    const confirmacao = window.confirm(mensagem);
    if(confirmacao){
      try {
        const response = await deleteDocumentApi(id);
        getDocumentsArticle();
        toast({
          title: "Arquivo deletado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const saveInfo = async () =>{
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("category_id", "4"); 
    formData.append("title", title);
    formData.append("file", file);

    if(title === ""){
      toast({
        title: "Preencha o campo Título",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (file === "" || file === null) {
      toast({
        title: "Selecione um arquivo para enviar",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      try {
        const response = await api.post("documents", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        toast({
          title: "Upload feito com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTitle("");
        setFile([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error(error);
        toast({
          title: "Não foi possível enviar o documento. Tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setTitle("");
        setFile([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
      getDocumentsArticle();
    }
    
  };

  useEffect(() => {
    getDocumentsArticle();
  }, []);

  return (
    <>
      <Box
        bg={"#ffff"}
        borderRadius={"15px"}
        boxShadow={"0px 7px 16px 4px #EAEAEA"}
        w={"57vw"}
        p={5}
        ml={"250px"}
        mt={"100px"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <h1 fontSize="md">Adicionar artigo</h1>
          <Button onClick={saveInfo}>Salvar</Button>
        </Box>
        <Box mr="20px" mt={"20px"}>
          <Text fontSize={12}>
            Envie arquivos nas extensões de docx. doc. e PDF.
          </Text>
          <Input type="file" ref={fileInputRef} onChange={handleFileChange} />
        </Box>

        <Box>
          <Text>Título</Text>
          <Input
            placeholder={"Digite Aqui"}
            value={title}
            onChange={handleTitleChange}
          />
        </Box>
      </Box>

      {savedInfos.map((info, index) => (
        <Box
          key={index}
          bg={"#ffff"}
          borderRadius={"15px "}
          boxShadow={"0px 7px 16px 4px #EAEAEA"}
          p={5}
          width={"880px"}
          ml={"250px"}
          mt={"20px"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px"}
            borderColor={"#D2D1D166"}
          >
            <Text fontSize={"20px"}>Artigos Provatis </Text>
            <Text color={"#979797"} fontSize={"12px"}>
              {format(parseISO(info.created_at), "dd/MM/yyyy")}
            </Text>
          </Box>
          <Box
            mt={"20px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          ></Box>
          <Box
            mt={"20px"}
            mb={"15px"}
            display={"flex"}
            alignItems={"end"}
          >
            <Text fontSize={"24px"}>{info.title}</Text>
            <Box>
              <Box background={"black"} borderRadius={"200px"} padding={1} marginLeft={"80px"}>
                <Link
                  href={
                    environment.baseURL +
                    "/storage/uploads/documents/" +
                    info.document_path
                  }
                >
                  <LuDownloadCloud
                    color="white"
                    fontSize={"24px"}
                    cursor={"pointer"}
                  />
                </Link>
              </Box>
            </Box>
            <Box marginLeft={"30px"}>
                <AiOutlineDelete
                  cursor={"pointer"}
                  fontSize={"25px"}
                  color="red"
                  onClick={() => deleteDocument(info.id)}
                />
              </Box>
          </Box>

          {/*
                    <Box display={'flex'} mt={'20px'} alignItems={'center'} gap={1}>
                        <Text fontSize={'16px'} color={'#888686'}>Essa dica foi útil?</Text>
                        <AiOutlineLike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Sim</Text>
                        <AiOutlineDislike fontSize={'16px'} color={'#888686'} />
                        <Text fontSize={'16px'} color={'#888686'}>Não</Text>
                        
                    </Box>
                    */}
        </Box>
      ))}
    </>
  );
}
