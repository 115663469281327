import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import {
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Container,
  Text,
  Input,
  NumberInputField,
  NumberInput,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
} from "@chakra-ui/react";

import { UserContext } from "../../../contexts/User";
import { SearchIcon } from "@chakra-ui/icons";

export default function Clients() {
  const { listClientsApi } = useContext(UserContext);
  const navigate = useNavigate();
  const [clients, setClients] = useState();
  const [allClients, setAllClients] = useState();
  const [searchClient, setSearchClient] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchID, setSearchID] = useState("");
  const perfil = "client";
  function goToProfile(value) {
    navigate(`/configuracoes/${perfil}/${value.id}`);
  }

  async function loadClients() {
    let filteredClients = allClients;
    if (!searchClient && !searchEmail && !searchID) {
      setClients(allClients);
    } else {
      if (searchID) {
        filteredClients = filteredClients.filter(
          (client) => client.user.id == searchID
        );
        setClients(filteredClients);
      } else {
        if (searchClient) {
          filteredClients = filteredClients.filter((client) =>
            client.user.name.toLowerCase().includes(searchClient.toLowerCase())
          );
        }
        if (searchEmail) {
          filteredClients = filteredClients.filter((client) =>
            client.user.email.toLowerCase().includes(searchEmail.toLowerCase())
          );
        }
        setClients(filteredClients);
        console.log(filteredClients);
      }
    }
  }
  function cleanFilters() {
    setSearchClient("");
    setSearchEmail("");
    setSearchID("");
    setClients(allClients);
  }
  async function listClients() {
    try {
      const clients = await listClientsApi();
      setClients(clients.data?.[0]);
      setAllClients(clients.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    listClients();
  }, []);

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{ base: 0, md: 300 }}
        minWidth={{ base: 0, md: "75vw" }}
      >
        <Flex display={"inline-block"} marginBottom={'15px'}>
            <Button colorScheme="green">
              <Link to="/adicionar/cliente"> Adicionar novo cliente </Link>
            </Button>
           </Flex>
        <Box
          bg={"#ffff"}
          borderRadius={"15px 15px 0 0"}
          boxShadow={"0px 7px 16px 0px #EAEAEA"}
        >
          <Box
            bg={"#ffff"}
            borderRadius={"15px 15px 15px 15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            padding={"2rem"}
          >
            <Box
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Box maxWidth={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Nome:
                </Text>
                <Input
                  variant="outline"
                  value={searchClient}
                  onChange={(e) => setSearchClient(e.target.value)}
                />
              </Box>

              <Box maxWidth={"12rem"} marginRight={"2rem"}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Email:
                </Text>
                <Input
                  variant="outline"
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                />
              </Box>
              <Box width={"12rem"} marginRight={'2rem'}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  ID do Cliente:
                </Text>
                <NumberInput
                  value={searchID}
                  onChange={(newID) => setSearchID(newID)}
                  variant="outline"
                  placeholder="Digite um ID do cliente"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
              <Box>
                <Button
                  marginTop={"1rem"}
                  colorScheme="green"
                  fontSize={"14px"}
                  leftIcon={<SearchIcon />}
                  onClick={() => loadClients()}
                >
                  Buscar
                </Button>
              </Box>

              <Box marginLeft={"auto"}>
                <Button
                  marginTop={"1rem"}
                  fontSize={"14px"}
                  onClick={cleanFilters}
                  bg="white"
                  border="1px solid black"
                >
                  <Text color={"black.100"} >Limpar Filtros</Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Flex display={"flex"} justifyContent={"center"}>
          <Box mt={"10px"} width={"100%"}>
            <Box>
              <TableContainer>
                <Table variant="striped" colorScheme="green">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Nome</Th>
                      <Th>Email</Th>
                      <Th>Ação</Th>
                    </Tr>
                  </Thead>
                  {clients && (
                    <Tbody>
                      {clients.map((index) => (
                        <Tr key={index.user.id}>
                          <Td>{index.user.id}</Td>
                          <Td>{index.user.name}</Td>
                          <Td>{index.user.email}</Td>
                          <Td>
                            {" "}
                            <Button
                              colorScheme="green"
                              onClick={() => goToProfile(index)}
                            >
                              Acessar perfil
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Flex>
      </Container>
    </>
  );
}
